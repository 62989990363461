import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import deviceIcon from "../../assets/images/deviceIcon.svg"
import projectIcon from "../../assets/images/projectIcon.svg"
import device2Icon from "../../assets/images/device2Icon.svg"
import coordinateIcon from "../../assets/images/coordianteIcon.svg"
import districtIcon from "../../assets/images/districtIcon.svg"
import stateIcon from "../../assets/images/stateIcon.svg"

const StationDetails = props => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card
                style={{
                  background: "#E3FDF2",
                  borderRadius: "24px,",
                  padding: "10px",
                }}
              >
                <CardBody>
                  <h5 className="text-black">Station Details</h5>
                  <Row className="mt-5">
                    <Col md={7}>
                      <Row>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={deviceIcon}
                              alt="deviceIcon"
                            />
                            <h5 className="mt-2"> Device ID</h5>
                            <p className="text-muted mb-4">222</p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={projectIcon}
                              alt="projectIcon"
                            />
                            <h5 className="mt-2"> Project Name</h5>
                            <p className="text-muted mb-4">name</p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={device2Icon}
                              alt="device2Icon"
                            />
                            <h5 className="mt-2">Device Name</h5>
                            <p className="text-muted mb-4">Device Name</p>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={coordinateIcon}
                              alt="coordinateIcon"
                            />
                            <h5 className="mt-2">Co ordinates</h5>
                            <p className="text-muted mb-4">222.11</p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={districtIcon}
                              alt="districtIcon"
                            />
                            <h5 className="mt-2"> District</h5>
                            <p className="text-muted mb-4">Kozhikode</p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={stateIcon}
                              alt="stateIcon"
                            />
                            <h5 className="mt-2">State</h5>
                            <p className="text-muted mb-4">Kerala</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <iframe
                        id="iframeid"
                        width="450"
                        height="250"
                        style={{border:0}}
                        src="https://www.google.com/maps/dir/?api=1&origin=Space+Needle+Seattle+WA&destination=Pike+Place+Market+Seattle+WA&travelmode=bicycling"
                      ></iframe>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

StationDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(StationDetails)
