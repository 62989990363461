import DataService from "../DataService"

const getWeatherData = async ({ limit, offset, deviceId }) => {
  let url = `data/list/?limit=${limit}&offset=${offset}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data.data
};

const exportWeatherDataCSV = async ({ limit, offset, deviceId, startDate, endDate }) => {
  let url = `csv/?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data
};

const exportWeatherDataPdf = async ({ limit, offset, deviceId, startDate, endDate }) => {
  let url = `pdf/?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data
};

const getCurrentWeatherData = async ({ limit, offset, date, deviceId }) => {
  let url = `data/current/date/?limit=${limit}&offset=${offset}&date=${date}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data.data
};

export { getWeatherData, getCurrentWeatherData,exportWeatherDataCSV,exportWeatherDataPdf };
