import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Container, Label, Form, FormFeedback, Input } from "reactstrap";

// Redux
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logoSm from "../../assets/images/logo-sm.svg";
import useLoginQuery from "store/auth/useAuthQuery";
import loginBg from "../../assets/images/login-bg.jpg";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const { mutateAsync: login, isSuccess } = useLoginQuery.Login(); 

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username:  '',
      password:  '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      if (values.username === "avg" && values.password === "avg") {
        localStorage.setItem('admin-token', 'admin-token')
        navigate('/dashboard')
      }
    }
  });

  document.title = "Login | AVG AWS";
  return (
    <React.Fragment>
      <div className="account-pages  pt-sm-5" style={{
          backgroundSize: "cover",
          backgroundImage: `url(${loginBg})`,
          height: "100vh",
        }}>
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Welcome Back..
                    </h5>
                    <p className="text-white-50">
                      Sign in to continue to Dashboard.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Username"
                          type="text"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                          </div>
                        </div>
                        <div className="col-sm-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                        </div>
                      </div>

                      {/* <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div> */}

                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center text-white">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  Copyright © 2024 Deepflow Technologies Private Limited | All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
